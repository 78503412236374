<template>
  <div>
    <a-breadcrumb style="margin-bottom: 24px">
      <a-breadcrumb-item v-for="(breadcrumb, index) in breadcrumbTexts" :key="index">
        <a v-if="index < breadcrumbTexts.length" @click="handleChange(index)">{{ breadcrumb }}</a>
        <span v-else>{{ breadcrumb }}</span>
      </a-breadcrumb-item>
    </a-breadcrumb>

    <a-card :body-style="{ padding: 0 }">
      <div slot="title">
        <span>数据列表</span>
        <a-button type="link" style="margin-left: 16px" @click="initData">
          <a-icon type="sync" /><span>刷新</span>
        </a-button>
      </div>

      <div slot="extra">
        <a-button
          v-if="$auth('fields_sorts') && matched.length === 3"
          :loading="sortLoading"
          :disabled="!sort"
          type="primary"
          shape="round"
          @click="handleSort"
        >
          <a-icon v-if="!sortLoading" type="rocket" /><span>发布</span>
        </a-button>
        <a-button
          v-if="$auth('fields_publish') && matched.length !== 3"
          :loading="sortLoading"
          :disabled="!sort"
          type="primary"
          shape="round"
          @click="handleSort"
        >
          <a-icon v-if="!sortLoading" type="rocket" /><span>发布</span>
        </a-button>
        <a-button
          v-if="$auth('fields_add') && matched.length !== 3"
          type="primary"
          shape="round"
          style="margin-left: 16px"
          @click="handleCreate"
        >
          <a-icon type="plus" /><span>新增</span>
        </a-button>
      </div>

      <main-table
        ref="table"
        v-if="$auth('fields_mains') && matched.length === 1"
        @check-children="checkChildren"
        @sort="sort = true"
      ></main-table>
      <secondary-table
        ref="table"
        v-if="matched.length === 2"
        @check-children="checkChildren"
        @sort="sort = true"
      ></secondary-table>
      <skill-table ref="table" v-if="matched.length === 3" @sort="sort = true"></skill-table>
    </a-card>
  </div>
</template>

<script>
import * as fieldApi from '@/api/field'
import MainTable from './components/MainTable'
import SecondaryTable from './components/SecondaryTable'
import SkillTable from './components/SkillTable'

const titles = ['领域', '子分类', '技能包']
export default {
  name: 'SkillManagementFieldList',
  components: {
    MainTable,
    SecondaryTable,
    SkillTable,
  },
  data() {
    return {
      matched: [''],
      sort: false,
      sortLoading: false,
    }
  },
  computed: {
    breadcrumbTexts() {
      return titles.slice(0, this.matched.length)
    },
  },
  activated() {
    const { ready, initData } = this

    if (ready) {
      initData()
    }
  },
  mounted() {
    const { $nextTick, initData } = this

    $nextTick(() => {
      this.ready = true
    })

    initData()
  },
  methods: {
    initData() {
      const { $refs, matched } = this

      this.sort = false

      $refs.table.initData({ parentId: matched[matched.length - 1] })
    },
    handleChange(level) {
      this.matched = this.matched.slice(0, level + 1)

      this.$nextTick(() => {
        this.initData()
      })
    },
    checkChildren(parentId) {
      this.matched.push(parentId)

      this.$nextTick(() => {
        this.initData()
      })
    },
    handleCreate() {
      const { matched } = this

      let path = '/skill-management/field/create'
      if (matched.length > 1) {
        path += `?pid=${matched[1]}`
      }

      this.$router.push({ path })
    },

    judgmentInterface(list) {
      return this.matched.length === 3 ? fieldApi.sortSkills(list) : fieldApi.sort(list)
    },
    handleSort() {
      const { $refs, $notification, initData } = this

      let list = $refs.table.list
      list = list.map((v) => ({ id: v.id, sequence: v.sequence }))

      this.sortLoading = true
      this.judgmentInterface(list)
        .then(() => {
          this.sort = false
          $notification['success']({ message: '操作成功' })
          initData()
        })
        .finally(() => (this.sortLoading = false))
    },
  },
}
</script>

<style lang="less" scoped></style>
