<template>
  <a-table rowKey="id" :data-source="list" :pagination="false" :loading="listLoading">
    <a-table-column key="status" title="序号" align="center" :ellipsis="true" width="7%">
      <template slot-scope="text, record, index">
        <span>{{ index + 1 }}</span>
      </template>
    </a-table-column>

    <a-table-column key="name" title="技能名称" align="center" data-index="name" :ellipsis="true" width="42%" />

    <a-table-column key="level" title="级别" align="center" data-index="level" :ellipsis="true" width="17%">
      <template slot-scope="level">
        <span>{{ level | SkillLevelText() }}</span>
      </template>
    </a-table-column>

    <a-table-column
      key="createTimestamp"
      title="添加时间"
      align="center"
      data-index="createTimestamp"
      :ellipsis="true"
      width="17%"
    >
      <template slot-scope="createTimestamp">
        <span>{{ createTimestamp | moment('YYYY-MM-DD') }}</span>
      </template>
    </a-table-column>

    <a-table-column key="statusstatus" title="状态" align="center" data-index="status" :ellipsis="true" width="17%">
      <template slot-scope="status">
        <span>{{ status | SkillStatusText() }}</span>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import { cloneDeep } from 'lodash'
import Sortable from 'sortablejs'
import * as fieldApi from '@/api/field'

export default {
  data() {
    return {
      query: {},
      listLoading: false,
      list: [],
    }
  },
  mounted() {
    this.handleSortable()
  },
  methods: {
    initData(query = {}) {
      const { getData } = this

      this.query = query

      return getData()
    },
    getData() {
      const { $nextTick, query } = this

      this.listLoading = true

      return fieldApi
        .skillList({ ...query })
        .then((res) => {
          // 刷新序号
          this.list = []
          $nextTick(() => {
            this.list = cloneDeep(res.data)
          })
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    handleSortable() {
      const tbody = this.$el.querySelector('.ant-table-tbody')

      Sortable.create(tbody, {
        onEnd: ({ newIndex, oldIndex }) => {
          const { $nextTick, list } = this
          const currRow = list.splice(oldIndex, 1)[0]
          list.splice(newIndex, 0, currRow)
          const sorted = list

          this.list = []
          $nextTick(() => {
            this.list = sorted
            this.$emit('sort')
          })
        },
      })
    },
    handleShowStatus({ id }) {
      const { $notification, getData } = this

      this.listLoading = true
      fieldApi
        .switchStatus(id)
        .then((res) => {
          $notification['success']({ message: '操作成功' })
          return getData()
        })
        .finally(() => (this.listLoading = false))
    },
  },
}
</script>

<style lang="less" scoped></style>
