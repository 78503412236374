<template>
  <a-table rowKey="id" :data-source="list" :pagination="false" :loading="listLoading">
    <a-table-column key="status" title="序号" align="center" :ellipsis="true" width="7%">
      <template slot-scope="text, record, index">
        <span>{{ index + 1 }}</span>
      </template>
    </a-table-column>

    <a-table-column key="name" title="领域名称" align="center" data-index="name" :ellipsis="true" width="14%" />

    <a-table-column key="level" title="级别" align="center" data-index="level" :ellipsis="true" width="14%">
      <template slot-scope="level">
        <span>
          {{ level | FieldLevelText() }}
        </span>
      </template>
    </a-table-column>

    <a-table-column
      key="skillCount"
      title="技能数量"
      align="center"
      data-index="skillCount"
      :ellipsis="true"
      width="14%"
    />

    <a-table-column key="isShow" title="是否显示领域" align="center" :ellipsis="true" width="14%">
      <template slot-scope="record">
        <a-switch
          :disabled="!$auth('fields_is_show')"
          :checked="record.isShow"
          size="small"
          @change="handleShowStatus(record)"
        />
      </template>
    </a-table-column>

    <a-table-column key="settings" title="设置" align="center" :ellipsis="true" width="20%">
      <template slot-scope="record">
        <a-space>
          <a-button
            v-if="$auth('fields_lower_level_add')"
            type="link"
            @click="
              $router.push({
                path: '/skill-management/field/create?pid=' + record.id,
              })
            "
          >
            新增下级领域
          </a-button>
          <a-button v-if="$auth('fields_childrens')" type="link" @click="handleChildren(record)">查看下级</a-button>
        </a-space>
      </template>
    </a-table-column>

    <a-table-column key="actions" title="操作" align="center" :ellipsis="true" width="17%">
      <template slot-scope="record">
        <a-space>
          <a-button
            v-if="$auth('fields_update')"
            type="link"
            @click="
              $router.push({
                path: '/skill-management/field/edit/' + record.id,
              })
            "
          >
            <a-icon type="edit" /><span>编辑</span>
          </a-button>
          <a-button v-if="$auth('fields_delete')" type="link" @click="handleRemove(record)" class="error-color">
            <a-icon type="delete" /><span>删除</span>
          </a-button>
        </a-space>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import { cloneDeep } from 'lodash'
import Sortable from 'sortablejs'
import * as fieldApi from '@/api/field'

export default {
  data() {
    return {
      query: {},
      listLoading: false,
      list: [],
    }
  },
  mounted() {
    this.handleSortable()
  },
  methods: {
    initData(query = {}) {
      const { getData } = this

      this.query = query

      return getData()
    },
    getData() {
      const { $nextTick, query } = this

      this.listLoading = true

      return fieldApi
        .mainList({ ...query })
        .then((res) => {
          // 刷新序号
          this.list = []
          $nextTick(() => {
            this.list = cloneDeep(res.data)
          })
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    handleSortable() {
      const tbody = this.$el.querySelector('.ant-table-tbody')

      Sortable.create(tbody, {
        onEnd: ({ newIndex, oldIndex }) => {
          const { $nextTick, list } = this
          const currRow = list.splice(oldIndex, 1)[0]
          list.splice(newIndex, 0, currRow)
          const sorted = list

          this.list = []
          $nextTick(() => {
            this.list = sorted
            this.$emit('sort')
          })
        },
      })
    },
    handleShowStatus({ id }) {
      const { $notification, getData } = this

      this.listLoading = true
      fieldApi
        .switchStatus(id)
        .then((res) => {
          $notification['success']({ message: '操作成功' })

          return getData()
        })
        .finally(() => (this.listLoading = false))
    },
    handleChildren({ id }) {
      this.$emit('check-children', id)
    },
    handleRemove(record) {
      const { $confirm, $notification, $info, getData } = this

      const confirm = $confirm({
        title: '确定删除',
        content: '确定删除当前分类及下属所有子分类',
        onOk: () => {
          return fieldApi
            .remove(record.id)
            .then(() => {
              $notification['success']({ message: '操作成功' })
              getData()
            })
            .catch((err) => {
              confirm.destroy()
              $info({
                title: '不可删除',
                content: err.msg,
              })
            })
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
@media (max-width: 1919px) {
  /deep/ .ant-btn {
    font-size: 12px;
  }
}
</style>
